<template>
	<div>
		<div class="d-block mb-4">
			<label class="label">
				Specific categories*
			</label>
			<CButton
				type="button"
				class="btn btn-add float-right"
				color="secondary"
				data-test-id="button-add"
				@click="$refs['modal-category'].open()"
			>
				{{ `${hasListItem ? 'Edit' : 'Add'} category` }}
			</CButton>
		</div>
		<div class="mt-3 category-box" :class="{ 'is-invalid': !isValid }">
			<BaseNoItemBanner v-if="!hasListItem" text="There are no available categories for this setting" />
			<ul v-else class="category-list">
				<li v-for="item in value" :key="item.id" class="list-item typo-body-2">
					{{ item.name }}
				</li>
			</ul>
		</div>
		<div
			v-if="invalidFeedback"
			class="invalid-feedback"
		>
			{{ invalidFeedback }}
		</div>
		<ModalCategory
			ref="modal-category"
			:selected-ids="selectedIds"
			data-test-id="modal-category"
			@onSubmit="handleListChange"
		/>
	</div>
</template>

<script>
import ModalCategory from '@/components/ModalCategory.vue';

export default {
	name: 'FormAvailableProductByCategory',

	components: {
		ModalCategory,
	},

	props: {
		value: {
			type: Array,
			default: () => [],
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		hasListItem() {
			return this.value && this.value.length > 0;
		},
		selectedIds() {
			return this.value.map((item) => item.id);
		},
	},

	methods: {
		handleListChange(list) {
			let values = null;
			values = list.map((item) => ({ id: item.id, name: item.name }));
			this.$emit('input', values);
		},
	},
};
</script>


<style lang="scss" scoped>
	.btn-add {
		min-width: rem(80);
	}

	.category-box {
		&.is-invalid {
			border: rem(1) solid $color-alert;
			border-radius: rem(4);
		}
	}

	.category-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16) rem(12);
		border-radius: rem(4);
		border: rem(1) solid $color-gray-400;
		list-style: none;

		.list-item {
			border-radius: rem(4);
			background-color: $color-gray-300;
			color: $color-black-90;
			display: inline-block;
			padding: 0 rem(6);
			margin-right: rem(12);
			margin-bottom: rem(12);
		}
	}
</style>
