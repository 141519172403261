<template>
	<div>
		<div class="d-block mb-4">
			<label class="label">
				Specific SKUs*
			</label>
			<CButton
				type="button"
				class="btn btn-add float-right"
				color="secondary"
				data-test-id="button-add"
				@click="$refs['modal-sku'].open()"
			>
				{{ `${hasListItem ? 'Edit' : 'Add'} SKU` }}
			</CButton>
		</div>
		<div v-if="!hasListItem" class="mb-3">
			<BaseNoItemBanner
				:class="{ 'is-invalid': !isValid }"
				:invalid-feedback="invalidFeedback"
				text="There are no available brands or SKUs for this setting"
			/>
			<div
				v-if="invalidFeedback"
				class="invalid-feedback"
			>
				{{ invalidFeedback }}
			</div>
		</div>
		<ul v-else class="list-item show-border" :class="{ 'is-invalid': !isValid }">
			<p class="show-text">
				{{ showProductSkus }}
			</p>
		</ul>
		<div
			v-if="invalidFeedback"
			class="invalid-feedback"
		>
			{{ invalidFeedback }}
		</div>
		<ModalSkuList
			ref="modal-sku"
			title="Select SKUs"
			:sku-list="value"
			@onConfirm="handleSKUChange"
		/>
	</div>
</template>

<script>
import ModalSkuList from '@/components/ModalSkuList.vue';

export default {
	name: 'FormAvailableProductBySKU',
	components: {
		ModalSkuList,
	},
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		list: {
			type: Array,
			default: () => [],
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		hasListItem() {
			return this.value && this.value.length > 0;
		},
		showProductSkus() {
			return this.value.join('; ');
		},
	},
	methods: {
		handleSKUChange(products) {
			this.$emit('input', products);
		},
	},
};
</script>

<style lang="scss" scoped>
	.is-invalid {
		border: 1px solid $color-alert !important;
		border-radius: rem(4);
	}

	.list-item {
		margin-bottom: 0;
		padding: rem(20) rem(16) 0;
		max-height: rem(320);
		overflow: auto;
	}

	.show-text {
		font-weight: $font-weight-semi-bold;
		color: $color-black-45;
	}

	.show-border {
		border: rem(1) solid $color-gray-400;
		border-radius: rem(4);
	}
</style>
