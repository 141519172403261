<template>
	<CListGroupItem
		:class="{ 'is-active' : isSelected }"
		class="item d-flex align-items-center px-2 py-0"
	>
		<CInputCheckbox
			:checked="checked"
			custom
			add-input-classes="input-checkbox"
			add-label-classes="input-label"
			:disabled="checked ? false : disabled"
			@update:checked="handleChecked"
		/>
		<div
			class="item-content d-flex flex-fill align-items-center"
			@click="handleSelect"
		>
			<div
				class="item-name flex-fill typo-body-2 my-0 p-2"
				data-test-id="name"
			>
				{{ name }}
			</div>
			<CBadge
				v-if="count"
				color="secondary"
				class="item-counter d-flex rounded-pill"
			>
				{{ count }}
			</CBadge>
			<FontAwesomeIcon
				v-if="hasArrow"
				:icon="['fas', 'chevron-right']"
				class="item-arrow"
			/>
		</div>
	</CListGroupItem>
</template>

<script>
export default {
	name: 'CategorySelectListItem',
	props: {
		itemId: {
			type: [String, Number],
			default: null,
		},
		count: {
			type: Number,
			default: 0,
		},
		name: {
			type: String,
			default: null,
		},
		checked: {
			type: Boolean,
			default: false,
		},
		hasArrow: {
			type: Boolean,
			default: false,
		},
		isSelected: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		handleSelect() {
			this.$emit('onSelect', this.itemId);
		},
		handleChecked(checked) {
			this.$emit('onChecked', this.itemId, checked);
		},
	},
};
</script>

<style lang="scss" scoped>
	.item {
		padding: rem(6) rem(26) rem(6) rem(12);
		background-color: $color-white;

		// when active
		&.is-active {
			background-color: $color-primary-25;
		}

		// .item-check-box
		&-checkbox {
			margin-bottom: rem(2);
		}

		// .item-content
		&-content {
			cursor: pointer;
		}

		// .item-name
		&-name {
			@include ellipsisOneline;
		}

		// .item-counter
		&-counter {
			min-width: rem(18);
			line-height: rem(18);
			padding: 0 rem(6);
		}

		// .item-arrow
		&-arrow {
			width: rem(18);
			height: rem(12);
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color-black-25;
		}
	}
</style>
