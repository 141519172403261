<template>
	<div class="main-wrapper mt-4 mb-3">
		<CRow>
			<CCol lg="12" class="d-flex">
				<h4 class="mb-4">
					General info
				</h4>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="6">
				<CInput
					v-model.trim="$v.formData.nameEn.$model"
					label="Tag Name (EN)*"
					maxlength="255"
					:is-valid="!$v.formData.nameEn.$error && null"
					:invalid-feedback="$t('global.error.required')"
					@blur="handleAutoFillSlug"
				/>
			</CCol>
			<CCol md="6">
				<CInput
					v-model.trim="$v.formData.nameTh.$model"
					label="Tag Name (TH)*"
					maxlength="255"
					:is-valid="!$v.formData.nameTh.$error && null"
					:invalid-feedback="$t('global.error.required')"
					@blur="handleAutoFillSlug"
				/>
			</CCol>
			<CCol md="12">
				<CInput
					v-model.trim="$v.formData.slug.$model"
					label="Slug*"
					:is-valid="!$v.formData.slug.$error && null"
					:invalid-feedback="slugErrors"
					description="Allow letters, numbers, and ( - )"
					@input="clearError"
				/>
			</CCol>
			<CCol md="3">
				<BaseInputNumber
					v-model="$v.formData.priority.$model"
					label="Priority*"
					:is-valid="!$v.formData.priority.$error"
					:invalid-feedback="priorityErrors"
					placeholder="0"
				/>
			</CCol>
			<CCol
				md="12"
				class="d-flex align-items-center margin-bottom-32 mt-4"
			>
				<label class="toggle-label" for="tag-filter-status">
					Status
				</label>
				<CSwitch
					id="tag-filter-status"
					data-id="tag-filter-status"
					:checked.sync="formData.status"
					variant="3d"
					size="sm"
					class="switch-custom toggle-status"
					color="success"
				/>
				<span>{{ statusText }}</span>
			</CCol>
		</CRow>

		<hr>

		<CRow>
			<CCol md="12">
				<h4 class="mb-4">
					Active dates
				</h4>
			</CCol>
			<CCol md="4" class="mt-2">
				<BaseInputDate
					v-model="$v.formData.startDate.$model"
					:is-valid="!$v.formData.startDate.$error"
					label="Start date*"
					:invalid-feedback="$t('global.error.required')"
					placeholder="Select date"
					@input="handleActiveStartDateChange"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2" class="mt-2">
				<div class="form-group">
					<BaseInputTime
						v-model="$v.formData.startTime.$model"
						:is-valid="!$v.formData.startTime.$error"
						:invalid-feedback="errorStartTime"
						label="Start time*"
					/>
				</div>
			</CCol>

			<CCol md="4" class="mt-2">
				<BaseInputDate
					v-model="formData.endDate"
					:disabled="!$v.formData.startDate.$model || $v.formData.startDate.$invalid "
					:min-date="formData.startDate"
					label="End date"
					placeholder="Select date"
					data-test-id="end-date"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2" class="mt-2">
				<BaseInputTime
					v-model="$v.formData.endTime.$model"
					:disabled="!$v.formData.startDate.$model || $v.formData.startDate.$invalid || !formData.endDate"
					:is-valid="!$v.formData.endTime.$error"
					:invalid-feedback="errorEndTime"
					label="End time"
				/>
			</CCol>
		</CRow>

		<hr>

		<CRow>
			<CCol md="12">
				<h4>Available Product</h4>
				<span class="typo-body-2 color-black-45">Set available product for tag filter</span>
			</CCol>
			<CCol md="12" class="mt-4 mb-5">
				<label class="label label-rule d-block">
					Add product by
				</label>
				<BaseButtonGroup
					:list="TAG_FILTER_PRODUCT_BY_TYPE_OPTIONS"
					:value="formData.configBy"
					@input="handleProductTypeChange"
				/>
			</CCol>
			<CCol md="12">
				<component
					:is="availableProductComponent"
					v-model="formData.configData"
					:is-valid="!$v.formData.configData.$error"
					:invalid-feedback="configDataError"
					@onChange="handleAvailableProductChange"
					@input="clearError"
				/>
				<hr class="mt-5 mb-5">
			</CCol>
		</CRow>
		<BaseActionPanelStickyFooter
			:is-edit="isEdit"
			:disabled-confirm="isSubmitting"
			:remove-text="isEdit ? 'Remove' : null"
			content-class="main-wrapper"
			@onRemove="isEdit ? $refs['modal-remove'].open() : null"
			@onConfirm="handleSubmit"
			@onCancel="redirectToTagFilterList"
		/>
		<BaseModalConfirmDelete
			ref="modal-confirm-discard"
			title="Discard?"
			description="You will be lost all added information. Are you sure to discard?"
			delete-button-text="Discard"
			@onSuccess="handleConfirmDiscard"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteTagFilter.bind(null, formData.id)"
			title="Remove this tag filter?"
			description="By removing this, the tag filter will be removed from the system and will no longer available for customers on the Studio7 website."
			@onSuccess="redirectToTagFilterList"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import { required, maxLength, helpers, minValue } from 'vuelidate/lib/validators';
import FormAvailableProductBySKU from '@/components/FormAvailableProductBySKU.vue';
import FormAvailableProductByCategory from '@/components/FormAvailableProductByCategory.vue';
import slugRegex from '../regex/slug';
import {
	TAG_FILTER_STATUS,
	TAG_FILTER_STATUS_LABEL,
	TAG_FILTER_PRODUCT_BY_TYPE,
	TAG_FILTER_PRODUCT_BY_TYPE_OPTIONS,
} from '../enums/tagFilters';
import { ROUTE_NAME } from '../enums/route';

const slugify = require('slugify');

export default {
	name: 'TagFilterForm',

	validations() {
		return {
			formData: {
				nameEn: {
					required,
					maxLength: maxLength(255),
				},
				nameTh: {
					required,
					maxLength: maxLength(255),
				},
				slug: {
					required,
					slugFormat: helpers.regex('slug', slugRegex),
					duplicate: () => !this.isSlugDuplicate,
				},
				priority: {
					required,
					minValue: minValue(0),
				},
				startDate: {
					required,
				},
				startTime: {
					required,
					validTime(time) {
						return time.length === 5;
					},
				},
				endTime: {
					validTime(time) {
						return time.length === 5 || time.length === 0;
					},
				},
				configData: {
					required,
					dataValid: () => !this.isAvailableProductError,
				},
			},
		};
	},

	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		defaultData: {
			type: Object,
			default: null,
		},
		isSlugDuplicate: {
			type: Boolean,
			default: false,
		},
		isAvailableProductError: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const {
			id,
			nameEn = null,
			nameTh = null,
			slug = null,
			priority = 10,
			status = false,
			startDate = null,
			startTime = '00:00',
			endDate = null,
			endTime = '23:59',
			configBy = TAG_FILTER_PRODUCT_BY_TYPE_OPTIONS[0].value,
			configData = [],
		} = this.defaultData || {};

		return {
			TAG_FILTER_PRODUCT_BY_TYPE_OPTIONS,

			formData: {
				id,
				nameEn,
				nameTh,
				slug,
				priority,
				status,
				startDate,
				startTime,
				endDate,
				endTime,
				configBy,
				configData,
			},
		};
	},

	computed: {
		...mapState('tagFilters', {
			edit: 'edit',
		}),

		slugErrors() {
			if (!this.$v.formData.slug.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.slug.slugFormat) {
				return this.$t('global.slug.invalid');
			}

			if (!this.$v.formData.slug.duplicate) {
				return this.$t('global.slug.duplicateSlug');
			}

			return null;
		},
		priorityErrors() {
			if (!this.$v.formData.priority.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.priority.minValue) {
				return this.$t('global.error.greaterThan', { field: 'Priority', number: 0 });
			}

			return null;
		},
		configDataError() {
			if (!this.$v.formData.configData.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.configData.dataValid) {
				return this.$t('global.error.invalid');
			}

			return null;
		},
		statusText() {
			return this.formData.status ? TAG_FILTER_STATUS_LABEL[TAG_FILTER_STATUS.ACTIVE] : TAG_FILTER_STATUS_LABEL[TAG_FILTER_STATUS.INACTIVE];
		},
		errorStartTime() {
			if (!this.$v.formData.startTime.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.startTime.validTime) {
				return this.$t('global.error.timeInvalidFormat');
			}

			return null;
		},
		errorEndTime() {
			if (!this.$v.formData.endTime.validTime) {
				return this.$t('global.error.timeInvalidFormat');
			}

			if (!this.$v.formData.endTime.after) {
				return this.$t('global.error.timeAfter', { time: this.formData.startTime });
			}

			return null;
		},
		availableProductComponent() {
			return this.formData.configBy === TAG_FILTER_PRODUCT_BY_TYPE.CATEGORY
				? FormAvailableProductByCategory
				: FormAvailableProductBySKU;
		},
	},

	methods: {
		...mapActions({
			deleteTagFilter: 'tagFilters/deleteTagFilter',
			clearTagFilterCreateError: 'tagFilters/clearTagFilterCreateError',
			clearTagFilterEditError: 'tagFilters/clearTagFilterEditError',
		}),
		handleUpdateStatus(value) {
			this.formData.status = value;
		},
		handleActiveStartDateChange(value) {
			if (
				!this.formData.endDate ||
				dayjs(value).isAfter(dayjs(this.formData.endDate))
			) {
				this.formData.endDate = value;
			}
		},
		handleProductTypeChange(value) {
			this.clearError();

			if (this.formData.configBy === value) {
				return;
			}

			if (this.formData.configData.length) {
				this.$refs['modal-confirm-discard'].open();
			} else {
				this.formData.configBy = value;
			}
		},
		handleAvailableProductChange(list) {
			this.formData.configData = list ? [...list] : [];
			this.clearError();
		},
		handleConfirmDiscard() {
			this.formData.configData = [];
			this.formData.configBy = TAG_FILTER_PRODUCT_BY_TYPE_OPTIONS
				.find((type) => type.value !== this.formData.configBy).value;
		},
		handleAutoFillSlug() {
			if (!this.slug) {
				this.formData.slug = slugify(this.formData.nameEn, {
					lower: true,
					strict: true,
				});
				this.$v.formData.slug.$touch();
			}
		},
		redirectToTagFilterList() {
			this.$router.push({ name: ROUTE_NAME.TAG_FILTER_LIST });
		},
		handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			const newConfigData = this.formData.configBy === TAG_FILTER_PRODUCT_BY_TYPE.CATEGORY
				? this.formData.configData.map((item) => item.id)
				: this.formData.configData;

			const params = {
				...this.formData,
				configData: newConfigData,
			};

			this.$emit('onSubmit', params);
		},
		clearError() {
			if (this.isEdit) {
				this.clearTagFilterEditError();
			} else {
				this.clearTagFilterCreateError();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	hr {
		margin: rem(40) 0;
	}

	.toggle-label {
		margin-right: rem(24);
	}

	.toggle-status {
		margin-right: rem(12);
	}
</style>
